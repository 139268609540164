import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["unitPrice", "quantity", "subtotal", "subtotalText"];

  connect() {
    this.calculate();
  }

  calculate() {
    const newValue =
      parseInt(this.unitPriceTarget.value) *
        parseInt(this.quantityTarget.value) || 0;
    if (!isNaN(newValue)) {
      this.subtotalTarget.value = newValue;
      this.subtotalTextTarget.dataset.numberToCurrencyNumberValue = newValue;
    }
  }

  resetToZero() {
    this.subtotalTarget.value = 0;
  }
}
